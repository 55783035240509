// Import React and ReactDOM if necessary
import React from 'react';
import { createRoot } from 'react-dom/client';

// Define your React widget component
function Widget1(props) {
  return (
    <div>
      <h1>Widget 1</h1>
      <p>{props.message}</p>
    </div>
  );
}

// Function to initialize and render the widget
export function initializeWidget(container, config) {
  const reactRoot = createRoot(container);
  reactRoot.render(<Widget1 message={config.message} />);
}