import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { initializeWidget } from './pages/widget1';

const Widget1Route = () => {
  useEffect(() => {
    // Initialize the widget when the component mounts
    const container = document.getElementById('widget-container');
    initializeWidget(container, { message: 'Hello from the route!' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run once

  return (
    <div>
      <h1>Widget 1 Route</h1>
      <div id="widget-container"></div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/widget1"
          element={<Widget1Route />}
        />
        {/* Other routes */}
      </Routes>
    </Router>
  );
}

export default App;
